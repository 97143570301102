:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(
    to right,
    var(--SecondaryColor),
    var(--PrimaryColor)
  );
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
}

.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--whiteColorDeam);
  width: 100%;
  padding: 1rem;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(2, 15, 29, 0.242);

  .logo {
    color: var(--blackColor);
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 25px;
      color: var(--PrimaryColor);
      transform: translateY(5px);
    }
  }

  @media screen and (max-width: 768px) {
    .navBar {
      position: absolute;
      background: var(--whiteColorDeam);
      height: max-content;
      width: 80%;
      border-radius: 1rem;
      top: -30rem;
      left: 50%;
      padding: 1rem;
      transform: translate(-50%);
      z-index: 2000;
      box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
      transition: 0.7s ease-in-out;
    }

    .activeNavbar {
      top: 7rem;
    }

    .navList {
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin: auto;
      padding: 0.5rem 0;

      .navItem {
        padding: 0.5rem 0;

        .navLink {
          color: var(--textColor);
          font-size: 0.9rem;
          font-weight: 600;
          &:hover {
            color: var(--PrimaryColor);
          }
        }
      }

      .btn {
        margin-top: 1rem;

        a {
          font-weight: 600;
          color: var(--whiteColor);
        }
      }
    }

    .closeNavbar {
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      color: var(--PrimaryColor);
      &:hover {
        color: var(--SecondaryColor);
      }
    }
  }

  .toggleNavbar {
    .icon {
      font-size: 25px;
      color: var(--PrimaryColor);
    }
    &:hover.icon {
      color: var(--SecondaryColor);
    }
  }
}

@media screen and (min-width: 769px) {
  .closeNavbar {
    display: none;
  }
  .toggleNavbar {
    display: none;
  }

  .header {
    padding: 1.5rem 2rem;

    .navBar {
      .navList {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        .navItem {
          .navLink {
            color: var(--textColor);
            padding: 0 0.45rem;
            font-size: 0.9rem;
            font-weight: 500;

            &:hover {
              color: var(--PrimaryColor);
            }
          }
        }

        .btn {
          margin-left: 1rem;
          a {
            font-weight: 500;
            color: var(--whiteColor);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .header {
    .navBar {
      .navList {
        .navItem {
          padding: 0 0.7rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1240px) {
  .header {
    padding: 1rem 8.5rem;
  }
}
