:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(
    to right,
    var(--SecondaryColor),
    var(--PrimaryColor)
  );
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
}

.footer {
  width: 100%;
  position: relative;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  .videoDiv {
    position: absolute;
    width: 100%;
    height: 100%;

    video {
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: "";
      background: rgba(10, 84, 107, 0.307);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      mix-blend-mode: multiply;
    }
  }

  .secContent {
    width: 100%;
    height: max-content;
    padding: 2rem initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    row-gap: 2rem;
    z-index: 100;

    .contactDiv {
      justify-content: space-between;
      flex-direction: column;
      gap: 1rem;

      .text {
        small {
          font-size: 13px;
          font-weight: 400;
          color: var(--whiteColor);
        }

        h2 {
          color: var(--whiteColor);
          font-size: 1.8rem;
        }
      }

      .inputDiv {
        width: 100%;
        gap: 1rem;
        flex-direction: column;

        input {
          width: 100%;
          padding: 0.5rem 0.6rem;
          border-radius: 3rem;
          border: 1px solid var(--whiteColor);
          outline: none;
          background: transparent;
          color: var(--whiteColor);

          &::placeholder {
            color: var(--whiteColor);
            opacity: 0.6;
          }
        }
        .btn {
          width: 100%;
          justify-content: center;
          color: var(--whiteColor);
          font-weight: 500;
          gap: 0.5rem;

          .icon {
            font-size: 18px;
          }
        }
      }
    }

    .footerCard {
      position: relative;
      padding: 1rem 1rem 4rem;
      grid: 2rem;
      border-radius: 1rem;
      background: var(--bodyColor);
      width: 100%;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      overflow: hidden;

      .footerIntro {
        flex-basis: 50%;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        row-gap: 0.5rem;
      }

      .logoDiv {
        .logo {
          color: var(--blackColor);
          font-weight: 600;

          .icon {
            font-size: 25px;
            color: var(--PrimaryColor);
            margin-right: 10px;
          }
        }
      }

      .footerParagraph {
        font-size: 13px;
        color: var(--textColor);
      }

      .footerSocials {
        column-gap: 0.5rem;

        .icon {
          color: var(--blackColor);
          font-size: 20px;

          &:hover {
            color: var(--PrimaryColor);
          }
        }
      }

      .footerLinks {
        flex-basis: 50%;
        width: 100%;
        gap: 1rem;

        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        flex-wrap: wrap;

        .linkGroup {
          .groupTitle {
            display: block;
            font-weight: 600;
            color: var(--blackColor);
            margin-bottom: 0.5rem;
          }
          .footerList {
            font-size: 15px;
            color: var(--textColor);
            transition: 0.3s ease-in-out;

            .icon {
              font-size: 15px;
              color: var(--greyText);
            }
            &:hover {
              color: var(--PrimaryColor);
              transform: translateX(7px);

              .icon {
                color: var(--SecondaryColor);
              }
            }
          }
        }
      }
    }
  }
}
